:root{

/*--------------------------------------------------------------
# Colors
--------------------------------------------------------------*/
    /* Primary color */
    --primary-01: #02015E;
    --primary-02: #001B71;
    --primary-03: #5F7199;
    --primary-04: #656F85;

    /* Secondary color */
    --secondary-very-dark: #0BCCF9;
    --secondary-dark: #6AE0FB;
    --secondary-main: #A6E5F2;
    --secondary-light: #DEF5F9;
    --secondary-very-light: #EFFAFC;
    --secondary-lightest: #FBFEFF;

    /* Neutral color */
    --neutral-900:#1D1D1D;
    --neutral-700:#344054;
    --neutral-600:#404F59;
    --neutral-500:#85888E;
    --neutral-400:#98A2B3;
    --neutral-300:#D0D5DD;
    --neutral-200:#EAECF0;
    --neutral-100:#F2F4F7;
    --neutral-50:#F9FAFB;
    --neutral-white:#FFFFFF;

    /* Red color */
    --red-very-dark:#B42318;
    --red-dark:#D92D20;
    --red-main:#F04438;
    --red-light:#F97066;
    --red-very-light:#FDA29B;
    --red-lightest:#FECDCA;
    --red-ultralight:#FFF7F6;

    /* Yellow color */
    --yellow-very-dark:#B54708;
    --yellow-dark:#DC6803;
    --yellow-main:#F79009;
    --yellow-light:#FDB022;
    --yellow-very-light:#FEC84B;
    --yellow-lightest:#FEDF89;

    /* Green color */
    --green-very-dark:#067647;
    --green-dark:#079455;
    --green-main:#17B26A;
    --green-light:#47CD89;
    --green-very-light:#75E0A7;
    --green-lightest:#A9EFC5;

    /* Teal color */
    --teal-very-dark:#107569;
    --teal-dark:#0E9384;
    --teal-main:#15B79E;
    --teal-light:#2ED3B7;
    --teal-very-light:#5FE9D0;
    --teal-lightest:#99F6E0;

    /* Purple color */
    --purple-very-dark:#6927DA;
    --purple-dark:#7839EE;
    --purple-main:#875BF7;
    --purple-light:#A48AFB;
    --purple-very-light:#C3B5FD;
    --purple-lightest:#DDD6FE;

    /* Pink color */
    --pink-very-dark:#C11574;
    --pink-dark:#DD2590;
    --pink-main:#EE46BC;
    --pink-light:#F670C7;
    --pink-very-light:#FAA7E0;
    --pink-lightest:#FCCEEE;


/*--------------------------------------------------------------
# Fonts family
--------------------------------------------------------------*/
    --default-font-family: 'Mulish', sans-serif;


/*--------------------------------------------------------------
# Fonts Sizes
--------------------------------------------------------------*/
    --header-extra-large:30px;
    --header-large:      20px;
    --header-medium:     18px;
    --header-small:      14px;

    --body-medium:       16px;
    --body-small:        14px;
    --body-extra-small:  12px;
    --body-xx-small:     10px;

    --clickable-medium:  18px;
    --clickable-small:   14px;
    

/*--------------------------------------------------------------
# Fonts Weight
--------------------------------------------------------------*/
    --font-bold: 800;
    --font-semibold: 600;
    --font-regular: 400;
    --font-light: 200;


/*--------------------------------------------------------------
# Space size
--------------------------------------------------------------*/
    --spacing-xxxl:40px;
    --spacing-xxl: 32px;
    --spacing-xl:  24px;
    --spacing-l:   20px;
    --spacing-m:   16px;
    --spacing-s:   12px;
    --spacing-xs:  8px;
    --spacing-xxs: 4px;
    --spacing-xxxs:2px;
    --spacing-none:0px;


/*--------------------------------------------------------------
# Border radius
--------------------------------------------------------------*/
    --radius-l:   24px;
    --radius-m:   16px;
    --radius-s:   12px;
    --radius-xs:  8px;
    --radius-xxs: 4px;


/*--------------------------------------------------------------
# Box shadows
--------------------------------------------------------------*/
    --shadow-m:  0px 4px 8px -2px rgba(16, 24, 40, 0.10), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    --shadow-s:  0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
    --shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    --shadow-focus:0px 0px 0px 4px rgba(152, 162, 179, 0.24);
    --shadow-focus-primary: 0px 0px 0px 4px rgba(0, 27, 113, 0.24);
}
