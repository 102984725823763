@use '@angular/material' as mat;
@use './_theme-colors' as ct-theme; 

@import url('./variables.scss');

html {
    color-scheme: light;
    @include mat.theme((
        color:( 
          primary: ct-theme.$primary-palette,
          tertiary: ct-theme.$tertiary-palette,
        ),
        typography: var(--default-font-family),
        density: 0
    )); 
}

:root {
  @include mat.dialog-overrides((
    container-max-width:100%
  ));

  @include mat.form-field-overrides((
    container-text-size:var(--body-small),
    outlined-outline-color:var(--neutral-300),
    container-vertical-padding:var(--spacing-s),
    container-height: var(--spacing-xl),
    container-text-tracking: 1,
    outlined-disabled-outline-color:transparent,
    outlined-disabled-input-text-color:var(--neutral-700),
    outlined-disabled-label-text-color:var(--neutral-700)
  ));

  @include mat.select-overrides((
    panel-background-color:var(--neutral-white),
    trigger-text-size:var(--body-small),
    trigger-text-tracking:1,
    disabled-trigger-text-color:var(--neutral-700),
  ));

  @include mat.table-overrides((
    background-color:transparent,
    row-item-outline-color:var(--neutral-300),
    row-item-label-text-color:var(--neutral-700),
    header-headline-size:var(--body-extra-small),
    header-headline-weight:var(--font-bold),
    row-item-label-text-size:var(--body-extra-small)
  ));
  
  @include mat.paginator-overrides((
    container-background-color: var(--neutral-white),
  ));

  @include mat.tabs-overrides((
    background-color:var(--neutral-white),
    foreground-color:var(--neutral-white)
  ));

  @include mat.list-overrides((
    list-item-trailing-supporting-text-tracking:1,
    list-item-trailing-supporting-text-color:var(--primary-02),
    list-item-trailing-supporting-text-weight:var(--font-bold),
    list-item-trailing-supporting-text-size:var(--body-small),
    list-item-label-text-tracking:1,
    list-item-label-text-color:var(--neutral-600),
    list-item-label-text-weight:var(--font-semibold),
    list-item-label-text-size:var(--body-small)
  ));

  @include mat.dialog-overrides((
    container-shape:var(--radius-xl),
    container-color:var(--neutral-white),
    headline-padding:var(--spacing-m)
  ));

  @include mat.stepper-overrides((
    container-color: var(--neutral-white)
  ));

}


body {
  font-family: var(--default-font-family);
  background: var(--neutral-white);
}


.blur {
  background: #e4effb;
  background-image: url(./assets/img/welcome_banner.svg);
  filter: blur(10px);
  position: absolute;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: calc(100vh - 60px);
  margin-top: 60px;
}



 

/****************************************************************************************/
/*************************** Fin personalizacion colores  ******************************/
/***************************************************************************************/
 
/*--------------------------------------------------------------
  # Main
  --------------------------------------------------------------*/
.main {
  margin-top: 60px;
  padding: 20px 30px;
  transition: all 0.3s;
  overflow: auto;
  background: var(--secondary-lightest);
}

@media (max-width: 1199px) {
  .main {
    padding: 20px;
  }
}
  
mat-tab-body .main {
	margin: 0 !important;
	padding: 1em !important;
}


 /*--------------------------------------------------------------
  # Sidebar
  --------------------------------------------------------------*/



  .personalMenu .toc {
    display: none;
  }
  
  .personalMenu .nav-item label {
    width: 100%;
  }
  
  .personalMenu li[data-level="2"]>input[type="checkbox"]:checked+label>a,
  .personalMenu li[data-level="1"]>input[type="checkbox"]:checked+label>a {
    color: var(--neutral-700) !important;
  }
  
  .personalMenu input[type="checkbox"]:checked+label>a {
    background-color: #eee;
    color: var(--neutral-700) !important;
  }
  
  .personalMenu input[type="checkbox"]+label>a {
    width: 100%;
    display: inline-block;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
  }
  
  .personalMenu a {
    text-decoration: none;
  }
  
  .personalMenu li:not(.nav-item) a {
    padding-left: 2em;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }


  .sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 996;
    transition: all 0.3s;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
    }
    @media (max-width: 1199px) {
    .sidebar {
      left: -300px;
    }
    }
    .sidebar::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #fff;
    }
    .sidebar::-webkit-scrollbar-thumb {
    background-color: #aab7cf;
    }
    
    @media (min-width: 1200px) {
    #main, #footer {
      margin-left: 300px;
    }
    }
    @media (max-width: 1199px) {
    .toggle-sidebar .sidebar {
      left: 0;
    }
    }
    @media (min-width: 1200px) {
    .toggle-sidebar #main, .toggle-sidebar #footer {
      margin-left: 0;
    }
    .toggle-sidebar .sidebar {
      left: -300px;
    }
    }
    
    .sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
    }
    .sidebar-nav li {
    padding: 0;
    margin: 0;
    list-style: none;
    }
    .sidebar-nav .nav-item {
    margin-bottom: 5px;
    }
    .sidebar-nav .nav-heading {
      font-size: 11px;
      text-transform: uppercase;
      color: #899bbd;
      font-weight: 600;
      margin: 10px 0 5px 15px;
    }
    .sidebar-nav .nav-link {
      display: flex;
      align-items: center;
      font-weight: var(--font-semibold);
      font-size: var(--body-medium);
      color: var(--neutral-700) !important;
      transition: 0.3;
      background: #fff;
      padding: 10px 15px;
      border-radius: 4px;
    }
    .sidebar-nav .nav-link i {
      font-size: 16px;
      margin-right: 10px;
      color: var(--neutral-700)
    }

    .sidebar-nav .nav-link:hover {
      color: var(--neutral-900) !important;
      background: #f6f9ff;
    }
  
    .sidebar-nav .nav-link:hover i {
      color: var(--neutral-900) !important;
    }
  
    .sidebar-nav .nav-link .bi-chevron-down {
      margin-right: 0;
      transition: transform 0.2s ease-in-out;
    }
    .sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
      transform: rotate(180deg);
    }
    .sidebar-nav .nav-content {
      padding: 5px 0 0 0;
      margin: 0;
      list-style: none;
    }
    .sidebar-nav .nav-content a {
      display: flex;
      align-items: center;
      font-weight: var(--font-semibold);
      font-size: var(--body-medium);
      color: var(--neutral-700) !important;
      padding: 10px 0 10px 40px;
      transition: 0.3s;
    }
    .sidebar-nav .nav-content a i {
    font-size: 6px;
    margin-right: 8px;
    line-height: 0;
    border-radius: 50%;
    }
    .sidebar-nav .nav-content a:hover, .sidebar-nav .nav-content a.active {
      color: var(--neutral-900) !important;
    }
    .sidebar-nav .nav-content a.active i {
      color: var(--neutral-900) !important;
    }

/*--------------------------------------------------------------
# Table
--------------------------------------------------------------*/

.ct-table-section{
  margin: var(--spacing-m) 0px;
  border: 1px solid var(--neutral-300);
  border-radius: var(--radius-xs);
  box-shadow: var(--shadow-s);
  background: var(--neutral-white);
}

.ct-table-container{
  overflow: auto;

  table {
    border-radius: var(--radius-xs);
  }

  .mat-mdc-table-sticky {
    max-width: 100px;
    width: 60px;
    border-left: 1px solid var(--neutral-200);
    background: var(--neutral-white);
  }
}

.ct-paginator-container{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--neutral-300);

  mat-paginator{
    width: 100%;
  }
}


/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/

mat-form-field{
  width: 100%;
  margin-top: .5em;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

.header {
  transition: all 0.5s;
  z-index: 997;
  height: 60px;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
  background-color: #fff;
  padding: 0 20px;
}

  
.botonera {
  width: 100%;
	display: flex;
	flex-direction: row;
	justify-content:flex-end;
	gap: 10px;
}

.header .toggle-sidebar-btn {
  font-size: 32px;
  padding-right: 10px;
  padding-left: 0px;
}


.logo span {
  font-size: 14px;
  font-weight: 100;
  color: #000;
  font-family: "Nunito", sans-serif;
}

.logo span b {
  font-size: 19px;
  font-weight: bold;
  color: #02015E !important;
}

.logo span.img {
  background: #fff;
  background-image: url(./assets/img/logo.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
}

span.profilelogo {
  background: #fff;
  background-image: url(./assets/img/user-circle.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
}

.profilelogo.big {
  width: 150px;
  height: 150px;
}


.charts-empty-state {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: calc(100% - 35px);
  padding: 3em;
  opacity: .3;
}

.mat-paginator-container {
  justify-content: flex-start !important;
  font-family: var(--default-font-family);
}

.mat-column-position {
  max-width: 100px;
}

.element-body .mat-checkbox-disabled .mat-checkbox-label,
.element-body .mat-input-element:disabled,
.element-body .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix:after {
  color: #000000 !important;
}

tr.example-detail-row {
  height: 0 !important;
}

tr.example-element-row:not(.example-expanded-row):hover {
  background: whitesmoke !important;
}

tr.example-element-row:not(.example-expanded-row):active {
  background: #efefef !important;
}

.example-element-row td {
  border-bottom-width: 0 !important;
}

.example-element-detail {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.mat-expansion-panel:hover {
  background-color: #ccc;
}

.mat-expansion-panel-header {
  background-color: #e9e9e9;
}

.mat-expansion-panel-header.mat-expanded {
  height: 48px
}

.mat-accordion {
  margin-bottom: 10px;
}

.mat-expansion-panel-content {
  overflow: auto !important;
}

.mat-expansion-panel-body {
  padding: 16px 24px !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  font-family: 'Mulish', sans-serif !important;
  color: #ffffff !important;
  background-color: #02015E !important;
  border-color: #02015E !important;
  border-radius: 10px;
  font-size: 18px;
}

.btn:hover {
  color: #02015E !important;
  background: transparent !important;
}

.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: #000 !important;
}


.cardCustos {
  padding: 10px;
  margin-bottom: 10px;
}

.cardCustos mat-checkbox {
  margin-left: 25px;
}

.cardCustos mat-checkbox .mat-checkbox-label {
  margin: 4px 0 0 10px;
}


.pagetitle {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.pagetitle > div{
  display: flex;
  gap:var(--spacing-xs)
}

.mat-flat-button.mat-accent,
.mat-raised-button.mat-accent,
.mat-fab.mat-accent,
.mat-mini-fab.mat-accent {
  background-color: #02015E !important;
  font-size: 24px;
}


ngx-numeric-range-form-field-control {
  margin-top: -10px;
}

@media (min-width: 1200px) {

  #main,
  #footer {
    margin-left: 240px;
  }
}

.custom-modalbox.big {
  height: 90%;
}

.custom-modalbox main {
  margin-left: 0px !important;
  margin-top: 0px !important;
  padding: 0px !important;

}
.red-button {
  background-color: #B42318 !important;
  color: white;
}
.blue-button {
  background-color: #02015E  !important;
  color: white;
}

.cardCustos .mat-form-field-infix {
  border-top: none
}

.cardCustos .mat-form-field {
  width: 100% !important;
}


.cardCustos .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;
}

.cardCustos .mat-input-element {
  caret-color: #dee2e6 !important;
}

.obligatorio {
  color: red
}

.mat-tooltip {
    background-color: rgba(2, 1, 94, 0.8) !important; 
    color: white;
    font-family: 'Mulish', sans-serif !important;
    max-width: 450px !important;
}

.edit-panel {
  position: fixed !important;
  top: 0;
  right: 0;
  width: 760px;
  height: 100%;
  border-radius: 10px;
  box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow-y: auto;
  max-height: 100% !important;
  background: var(--neutral-white);
}

@media (max-width: 900px) {
  .edit-panel {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .custom-modalbox{
    width: 90% !important;
    min-width: 90% !important;
    max-width: 100% !important;

  }
}

.edit-panel .title{
  font-size: 18px;
  font-weight: bold;
  color: #1D1D1D;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  color: var(--Typography-Tertiary, #85888E);
  font-family: 'Mulish', sans-serif !important;
  
}

.mat-form-field-appearance-outline input,
.mat-form-field-appearance-outline textarea,
.mat-form-field-appearance-outline select,
.mat-form-field-appearance-outline .mat-select{
  color: var(--Typography-Dark, #1D1D1D);
  font-family: 'Mulish', sans-serif !important;
}


.mat-form-field-disabled .mat-form-field-prefix, 
.mat-form-field-appearance-outline .mat-input-element:disabled,
.mat-select-disabled .mat-select-value {
  color: var(--Typography-Tertiary, #85888E) !important;
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0);
}


.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #001B71 !important;
}

.save-button:disabled {
  background-color: #cccccc;
  border-color: #cccccc;
  cursor: not-allowed;
}

.close-button,
.edit-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}


.cardCustos ul {
  display: flex;
}

.cardCustos ul li {
  width: 300px;
}


.apexcharts-canvas {

  width: 100% !important;
}

.apexcharts-canvas svg {

  width: 100% !important;
}

th.mat-mdc-header-cell:first-of-type,
td.mat-mdc-cell:first-of-type,
td.mat-mdc-footer-cell:first-of-type {
  padding-left: 10px !important;
  /*padding-top: 8px;*/
}


.mat-dialog-container {
  overflow: hidden !important;
}

.edit-panel .mat-dialog-container {
  padding: 0px !important;
}


.nav-tabs-bordered .nav-link.active {
  background-color: #fff;
  color: #001B71;
  border-bottom: 2px solid #001B71;
}

.mat-progress-bar .mat-progress-bar-fill::after{
  background-color: #001B71 !important;
}

.nav-tabs-bordered .nav-link:hover,
.nav-tabs-bordered .nav-link:focus {
  color: #001B71;
}

.header-nav .profile .dropdown-header h6 {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: bold;
  font-family: 'Mulish', sans-serif !important;
  color: #444444;
}

.header-nav .nav-profile span {
  font-size: 16px;
  font-family: 'Mulish', sans-serif !important;
  font-weight: bold;
  color: #001B71;

}



  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  .footer {
    padding: 15px 10px;
    font-size: 14px;
    transition: all 0.3s;
    border-top: 1px solid #cddfff;
    }
    .footer .copyright {
    text-align: center;
    color: #012970;
    }
    .footer .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #012970;
    }
  
  /*--------------------------------------------------------------
    # Sensors Charts tooltip 
  --------------------------------------------------------------*/
  
  .chart-tooltip {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-family: 'Mulish', sans-serif;
  }
  
  .chart-tooltip-body {
    display: flex;
    flex-direction: column;
    padding: .5em;
     color: #666;
  }
  
  /*--------------------------------------------------------------
    # Status indicator 
  --------------------------------------------------------------*/
  
  .status-indicator {
    display: flex;
    gap: var(--Spaces-Spacing-XXS, 4px);
    padding: var(--Spaces-Spacing-XXS, 4px) var(--Spaces-Spacing-XS, 8px);
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-radius: var(--Radius-Radius-XXS, 4px);
    border: 1px solid var(--Border-Default, #EAECF0);
    background: var(--Background-White, #FFF);
  }
  
  .status-label{
    color: var(--Typography-Secondary, #344054);
    text-align: center;

    font-family: var(--default-font-family);
    font-size: var(--body-extra-small);
    font-weight: 600;
    line-height: 16px; 
  }

  .status-icon {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
  
  .status-icon.red {
    background-color: #F04438; /* Rojo para desconectado */
  }
  
  .status-icon.yellow {
    background-color: #F79009; /* Amarillo para sin perfil */
  }
  
  .status-icon.gray {
    background-color: #404F59; /* Gris para apagado */
  }

  .status-icon.gray-ligth {
    background-color: #8dc2ce; /* Azul para pendiente */
  }
  
  .status-icon.green {
    background-color: #17B26A; /* Verde para activo */
  }
  
  .status-icon.blue {
    background-color: #0BCCF9; /* Azul para pendiente */
  }
  
  
  /*--------------------------------------------------------------
    # Close panel button
  --------------------------------------------------------------*/
  
  .close-panel-button{
    display: flex;
    align-items: center;
    color: #333;
    background: transparent;
    border: none;
  }

  .close-panel-button mat-icon{
    line-height: 24px;
  }
  
  /*--------------------------------------------------------------
    # Custom modalBox
  --------------------------------------------------------------*/
  
  .custom-modalbox-big{
    min-width: 50% !important;
  }
  
  @media (max-width: 900px) {
    .custom-modalbox-big {
      width: 98% !important;
      max-width: 98% !important;
      height: 98% !important;
    }

  }
  
  
  /*--------------------------------------------------------------
    # Battery level Column
  --------------------------------------------------------------*/
  .battery-level{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5em;
  }
  
  .battery-level--default mat-icon{
    color: #404F59;
  }
  
  .battery-level--low mat-icon{
    color: #F04438;
  }
  
  .battery-level--mid mat-icon{
    color: #F79009;
  }
  
  .battery-level--high mat-icon{
    color: #17B26A;
  }
  
  
  /*--------------------------------------------------------------
    # Estilos para tabla general
  --------------------------------------------------------------*/
  .tablaPersonal .mat-paginator-page-size-select{width: 45px !important; }
  .tablaPersonal .mat-form-field-infix{min-width: 45px !important; }


  /*--------------------------------------------------------------
    # Estilos para panel de edición
  --------------------------------------------------------------*/

  .edit-container {
    background-color: var(--neutral-white);
    padding: var(--spacing-s);
    margin: var(--spacing-s);
  }

  .edit-container:last-child {
    margin-bottom: var(--spacing-xxxl); 
  }
  
  .edit-container .mat-form-field {
    width: 100%;
  }

  .edit-content {
    margin-top: var(--spacing-m);
  }

  .bordered {
    border: 1px solid var(--neutral-200);
    box-shadow: var(--shadow-xs);
    border-radius: var(--radius-m);
  }
  

  /*--------------------------------------------------------------
    # Text definitions
  --------------------------------------------------------------*/

  .header-large{
    font-family: var(--default-font-family);
    font-size: var(--header-large);
    font-weight: 600;
    line-height: 28px;
    color: var(--neutral-900);
  }

  .header-medium{
    font-family: var(--default-font-family);
    font-size: var(--header-medium);
    font-weight: 600;
    line-height: 28px;
    color: var(--neutral-900);
  }

  .ct-header{
    color: var(--neutral-900);
    font-family: var(--default-font-family);
    letter-spacing: 0;
    word-spacing: 0;
  }

  .ct-header-xl{
    font-size: var(--header-extra-large);
    font-weight: var(--font-semibold);
    line-height: 38px;
  }

  .ct-header-l{
    font-size: var(--header-large);
    font-weight: var(--font-semibold);
    line-height: 28px;
  }
  
  .ct-header-m{
    font-size: var(--header-medium);
    font-weight: var(--font-semibold);
    line-height: 24px;
  }

  .ct-header-s{
    font-size: var(--header-small);
    font-weight: var(--font-bold);
    line-height: 20px;
  }

  .ct-body{
    color: var(--neutral-900);
    font-family: var(--default-font-family);
    font-weight: var(--font-regular);
    letter-spacing: 0;
    word-spacing: 0;
  }

  .ct-body--semibold{
    font-weight: var(--font-semibold);
  }

  .ct-body-m{
    font-size: var(--body-medium);
    line-height: 20px;
  }

  .ct-body-s{
    font-size: var(--body-small);
    line-height: 20px;
  }

  .ct-body-xs{
    font-size: var(--body-extra-small);
    line-height: 16px;
  }

  .ct-body-xxs{
    font-size: var(--body-xx-small);
    line-height: 12px;
  }

  .ct-clickable{
    font-family: var(--default-font-family);
    font-weight: var(--font-regular);
    letter-spacing: 0;
    word-spacing: 0;
  }
 
  .ct-clickable--bold{
    font-weight: var(--font-bold);
  }

  .ct-clickable--semibold{
    font-weight: var(--font-semibold);
  }

  .ct-clickable-m{
    font-family: var(--clickable-medium);
  }

  .ct-clickable-s{
    font-family: var(--clickable-small);
  }



  /*--------------------------------------------------------------
    # Buttons definitions
  --------------------------------------------------------------*/
  
  .ct-button{
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-xxs);
    padding: var(--spacing-xs) var(--spacing-s);
    border-radius: var(--radius-xs, 8px);

    /*elements/S/Semibold*/
    font-size: var(--body-small);
    font-weight: var(--font-bold);
  }
  
  /* Primary */
  .ct-button--primary{
    color: var(--neutral-white);
    border: transparent;
    background: var(--primary-02);
    box-shadow: var(--shadow-xs);
  }

  .ct-button--primary:hover{
    background: var(--primary-01);
  }

  .ct-button--primary:focus{
    box-shadow: var(--shadow-focus-primary);
  }

  .ct-button--primary:disabled{
    background: var(--neutral-100);
    border-color: var(--neutral-200);
    color:var(--neutral-500)
  }

  .ct-button--primary.red{
    border: 1px solid var(--red-dark);
    background: var(--red-dark);
  }

  .ct-button--primary.red:hover{
    background: var(--red-very-dark);
  }


  /* Secondary */
  .ct-button--secondary{
    color: var(--neutral-900);
    border: 1px solid var(--neutral-300);
    background: var(--neutral-white);
    box-shadow: var(--shadow-xs);
  }

  .ct-button--secondary:hover{
    background: var(--neutral-100);
  }

  .ct-button--secondary:focus{
    box-shadow: var(--shadow-focus);
  }

  .ct-button--secondary:disabled{
    border-color: var(--neutral-200);
    color:var(--neutral-500)
  }
  
  /* Tertiary */
  .ct-button--tertiary{
    color: var(--neutral-700);
    border: none;
    background: transparent;
  }

  .ct-button--tertiary:hover{
    background: var(--neutral-100);
  }

  .ct-button--tertiary:disabled{
    color:var(--neutral-500)
  }

  /* Link */
  .ct-button--link{
    color: var(--primary-02);
    border: none;
    background: transparent;
  }

  .ct-button--link:hover{
    background: var(--neutral-100);
    color: var(--primary-01);
  }

  .ct-button--link:focus{
    color: var(--neutral-900)
  }

  .ct-button--link:disabled{
    color: var(--neutral-500)
  }

  /* Icon */
  .ct-button--icon{
    border: none;
    background: transparent;
    color: var(--primary-02);
    padding: var(--spacing-xs) var(--spacing-xxs);
    opacity: 1;
  }

  .ct-button--icon:hover{
    background: var(--neutral-100);
    color:var(--neutral-500)
  }

  .ct-button--icon:focus{
    color:var(--neutral-900)
  }
  
  .ct-button--icon:disabled{
    opacity: .5;
  }

  .ct-button--fab {
    padding: var(--spacing-xs);
    border: 1px solid var(--neutral-300);
    background: var(--neutral-100);
    border-radius: 50%;
  }

  .ct-button--fab:hover{
    background: var(--neutral-100);
    color:var(--neutral-500)
  }

  .ct-button--fab:focus{
    color:var(--neutral-900)
  }
  
  .ct-button--fab:disabled{
    opacity: .5;
  }
  
  /* Iconos en botones */
  mat-icon.ct-icon{
    width: 20px;
    height: 20px;
    line-height: 18px;
  }

  .ct-icon.red{
    color: var(--red-very-dark);
  }

  .ct-icon.green{
    color: var(--green-very-dark);
  }

  .ct-icon.blue{
    color: var(--primary-01);
  }
    
/*--------------------------------------------------------------
    # Dialog definitions
  --------------------------------------------------------------*/

  .ct-dialog-header{
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-m);
  }

  .ct-dialog-header span,
  .ct-dialog-header div{
    width: 100%;
  }

  .ct-dialog-content{
    max-height: calc(100vh - 180px) !important;
  }
  
  .ct-dialog-content.ct-dialog-content__extended{
    max-height: calc(100vh - 84px) !important;
  }
  
/*--------------------------------------------------------------
    # 
  --------------------------------------------------------------*/

  .entero-true{
    width: 100%;
  }
  
  @media (max-width: 900px) {
    .hide-on-mobile {
      display: none;
    }
  }
    

/*--------------------------------------------------------------
    # Selector With Filter
  --------------------------------------------------------------*/
  .ct-select-dropdown{
    background: var(--neutral-white);
    border: 1px solid var(--neutral-400);
    box-shadow: var(--shadow-m);
  }

  .ct-select-dropdown mat-label{
    width: 100%;
    height: 40px;
    display: block;
  }

  .ct-select-dropdown input{
    height: 40px;
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--neutral-400);
    padding: 0px .75em;
  }

  .ct-select-dropdown input:active,
  .ct-select-dropdown input:focus{
    outline: none;
  }
  
  .ct-select-dropdown mat-option{
    color: var(--Typography-Tertiary);
    font-family: var(--default-font-family);
    font-size: var(--body-small);
    letter-spacing: 0;
  }

  .ct-select-dropdown__empty span{
    text-align: center;
    color: var(--neutral-500);
  }

  /*--------------------------------------------------------------
    # Tabs
  --------------------------------------------------------------*/
  .mat-tab-body-wrapper {
    transition: none !important;
  }
  
  .mat-tab-body {
    transform: none !important;
    animation: none !important;
  }


/*--------------------------------------------------------------
    # Legend
  --------------------------------------------------------------*/

.ct-legend{    
  margin: 1em 0px;
  padding: 1em;
  border: 1px solid var(--neutral-300);
  border-radius: 8px;
  width: fit-content;
}

.ct-legend-container{
  display: flex;
  gap: 1em;
}

.ct-legend ul{
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.ct-legend-item{
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.ct-legend-item .dot{
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.ct-legend-item .dot.blue{
  background: var(--secondary-dark);
}

.ct-legend-item span{
  font-family: var(--default-font-family);
  font-size: var(--body-extra-small);
  font-weight: var(--font-regular);
}


/*--------------------------------------------------------------
    # Tooltip
  --------------------------------------------------------------*/


.ct-tooltip{
	font-size: var(--body-extra-small);
	font-family: var(--default-font-family);
}




input[type="password"] {
  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
  
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
}

.mat-dialog-content{
  padding: 10px 24px !important;
}



/*--------------------------------------------------------------
    # Tabs
  --------------------------------------------------------------*/

.ct-tabs-container{
  @include mat.tabs-overrides((
    active-indicator-color:transparent,
    active-focus-indicator-color:transparent,
    active-hover-indicator-color:transparent,
    active-ripple-color:transparent,
    inactive-ripple-color:transparent,
    container-height:36px
  ));

  .ct-tabs .mdc-tab.mdc-tab--active .mdc-tab-indicator{
    border: 1px solid var(--neutral-200);
    border-bottom: none;
    border-radius: var(--radius-m) var(--radius-m) 0px 0px;
    box-shadow: 0px 3px 5px var(--neutral-300);
    background: var(--secondary-lightest);
  }

  .ct-tabs .mdc-tab:hover .mdc-tab-indicator{
    border: 2px solid var(--neutral-200);
    border-bottom: none;
    border-radius: var(--radius-m) var(--radius-m) 0px 0px;
  }

  .ct-tabs .mat-mdc-tab:hover .mdc-tab__ripple::before {
    opacity: 0 !important;
  }

}